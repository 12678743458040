import React, { useRef } from 'react';
import PropTypes from 'prop-types';
// import { SkipNavLink, SkipNavContent } from '@components/SkipNav';

import is404Check from '@utils/is404Check';
import DataContext from '@context/DataContext';
import LocaleContext from '@context/LocaleContext';
import LocationContext from '@context/LocationContext';

import Footer from '@components/Footer';
import Header from '@components/Header';
import Hero from '@components/Hero';

import { container, pageContent } from './layout.module.css';

export default function Layout({
  children,
  data,
  location,
  pageResources = {
    page: { path: '' },
  },
}) {
  const is404Page = is404Check(pageResources.page.path);

  // Set showHero to 'false' if there is no hero key in the data object or if the page is the 404 page
  let showHero = Boolean(data.hero);
  if (is404Page) {
    showHero = false;
  }
  const stickyRef = useRef(null);

  return (
    <DataContext.Provider value={data}>
      <LocationContext.Provider value={location}>
        <LocaleContext.Provider value={data?.page?.locale ? data.page.locale : ''}>
          <div className={container}>
            {/* <SkipNavLink /> */}
            <Header hasHero={showHero} stickyRef={stickyRef} />
            {showHero && <Hero />}
            {/* <SkipNavContent> */}
            <div className={pageContent}>{children}</div>
            {/* </SkipNavContent> */}
            <Footer />
          </div>
        </LocaleContext.Provider>
      </LocationContext.Provider>
    </DataContext.Provider>
  );
}

Layout.propTypes = {
  children: PropTypes.any.isRequired,
  data: PropTypes.object,
  location: PropTypes.object.isRequired,
  pageResources: PropTypes.object.isRequired,
};

Layout.defaultProps = {
  data: {},
};
