import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import useDataContext from '@hooks/useDataContext';

import * as styles from './languageSelector.module.css';

const localeToLabel = {
  'en-GB': 'eng',
  'sv-SE': 'sv',
};

const frontpageQuery = graphql`
  query GetFrontpages {
    allFrontpage(sort: { locale: DESC }) {
      nodes {
        id
        locale
        slug
      }
    }
    datoCmsSite {
      locales
    }
  }
`

export default function LanguageSelector({ navClose }) {
  let { translations } = useDataContext();
  const data = useStaticQuery(frontpageQuery)

  function handleClose() {
    if (typeof navClose === 'function') {
      navClose();
    }
  }

  if (!translations) {
    translations = data.allFrontpage
  } else if (translations.length < data.datoCmsSite?.locales?.length) {
    const newTranslations = []
    for (const frontpage of data.allFrontpage.nodes) {
      const translationPerLocale = translations.find(translation => translation.locale === frontpage.locale)
      if (translationPerLocale) {
        newTranslations.push(translationPerLocale)
      } else {
        newTranslations.push(frontpage)
      }
    }
    translations = newTranslations
  }

  return (
    <div className={styles.div}>
      {translations.nodes.map(({ id, locale: translationsLocale, slug }) => (
        <Link
          activeClassName={styles.active}
          className={styles.link}
          key={id}
          onClick={handleClose}
          to={slug}
        >
          {localeToLabel[translationsLocale]}
        </Link>
      ))}
    </div>
  );
}

LanguageSelector.defaultProps = {
  navClose: undefined,
};

LanguageSelector.propTypes = {
  navClose: PropTypes.func,
};
