import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import useLocaleContext from '@src/hooks/useLocaleContext';

import { div, p } from './IEBanner.module.css';

const ieTextQuery = graphql`
  query GetIEText {
    allSetting {
      nodes {
        internetExplorerBanner
        locale
      }
    }
  }
`;
export default function IEBanner() {
  const [isIE, setIsIE] = useState(false);
  const locale = useLocaleContext();
  const { allSetting } = useStaticQuery(ieTextQuery);
  const text = allSetting.nodes.find((setting) => setting.locale === locale);

  useEffect(() => {
    if (window.document.documentMode) {
      setIsIE(true);
    }
  }, []);

  if (isIE) {
    return (
      <div className={div}>
        <p className={p}> {text}</p>
      </div>
    );
  }
  return null;
}
