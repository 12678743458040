import React from 'react';

import useDataContext from '@hooks/useDataContext';

import HeroFrontpage from './FrontPage';
import HeroStandard from './Standard';

export default function Hero() {
  const { hero: heroData } = useDataContext();
  if (heroData) {
    const { hero } = heroData;

    if (hero) {
      if (hero.link) {
        return (
          <HeroFrontpage
            buttonText={hero.link.title}
            image={hero.image}
            quotes={hero.quotes}
            subtitle={hero.subtitle}
            title={hero.title}
            url={hero.link.slug}
          />
        );
      }

      return (
        <HeroStandard
          id={hero.id}
          image={hero.image}
          subtitle={hero.subtitle}
          title={hero.title}
        />
      );
    }
  }

  return null;
}
