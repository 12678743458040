import React from 'react';
import PropTypes from 'prop-types';

import { wrapper } from './wrapper.module.css';

export default function Wrapper({ url, children }) {
  return (
    <>
      {url ? (
        <a
          className={wrapper}
          href={url}
          rel="noopener noreferrer"
          target="_blank"
        >
          {children}
        </a>
      ) : (
        <div className={wrapper}>{children}</div>
      )}
    </>
  );
};

Wrapper.defaultProps = {
  url: '',
};

Wrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  url: PropTypes.string,
};
