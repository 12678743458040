import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { array } from 'prop-types';

import useLocaleContext from '@hooks/useLocaleContext';

import NavDesktop from './NavDesktop';
import NavMobile from './NavMobile';

import * as styles from './nav.module.css';

const query = graphql`
  {
    en_GB: allNewsarticle(filter: { locale: { eq: "en-GB" } }) {
      nodes {
        slug
      }
    }
    sv_SE: allNewsarticle(filter: { locale: { eq: "sv-SE" } }) {
      nodes {
        slug
      }
    }
    allFrontpage {
      nodes {
        locale
        showNews
        slug
      }
    }
  }
`;

export default function Nav({ navigationTitles }) {
  const data = useStaticQuery(query);
  const locale = useLocaleContext();

  let underscoreLocale = '';
  let newsLink = null;
  if (locale) {
    underscoreLocale = locale.split('-').join('_');
    if (data?.[underscoreLocale]?.nodes?.length) {
      const newsarticles = data[underscoreLocale].nodes.filter(newsarticle => newsarticle.slug);
      if (newsarticles.length) {
        if (data.allFrontpage.nodes?.length) {
          const frontpage = data.allFrontpage.nodes.find(fp => fp.locale === locale);
          if (frontpage) {
            if (frontpage.showNews) {
              const newsarticle = newsarticles[0]
              newsLink = {
                slug: newsarticle?.slug ? newsarticle.slug : '#',
                title: locale === 'sv-SE' ? 'Aktuellt' : 'News',
              };
            }
          } else {
            newsLink = null;
          }
        } else {
          newsLink = null;
        }
      }
    }
  }

  return (
    <>
      <div className={`${styles.div} ${styles.desktop}`}>
        <NavDesktop navigationTitles={navigationTitles} newsLink={newsLink} />
      </div>

      <div className={`${styles.div} ${styles.mobile}`}>
        <NavMobile navigationTitles={navigationTitles} newsLink={newsLink} />
      </div>
    </>
  );
};

Nav.propTypes = {
  navigationTitles: array.isRequired,
};
