import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';

import Hamburger from '../Hamburger';
import LanguageSelector from '../LanguageSelector';

import * as styles from './navMobile.module.css';

const frontpagesQuery = graphql`
  query {
    allFrontpage {
      nodes {
        slug
      }
    }
  }
`

export default function NavMobile({ navigationTitles, newsLink }) {
  const menu = useRef();
  const [isActive, setIsActive] = useState(false);

  const { allFrontpage } = useStaticQuery(frontpagesQuery);
  const allFrontpageSlugs = allFrontpage.nodes.map(frontpage => frontpage.slug)

  const navToggle = useCallback(() => setIsActive(!isActive), [isActive]);
  const navClose = useCallback(() => setIsActive(false), [setIsActive]);
  return (
    <div className={styles.mobileNav} ref={menu}>
      {isActive ? (
        <nav className={styles.nav}>
          {navigationTitles.map((item) => (
            <Link
              activeClassName={styles.active}
              className={styles.link}
              key={item.id}
              onClick={navToggle}
              partiallyActive={!allFrontpageSlugs.includes(item.link.slug)}
              to={item.link.slug}
            >
              {item.title}
            </Link>
          ))}
          {newsLink ? (
            <Link
              activeClassName={styles.active}
              className={styles.link}
              key={newsLink.title}
              onClick={navClose}
              to={newsLink.slug}
            >
              {newsLink.title}
            </Link>
          ) : null}
          <div className={styles.langSelect}>
            <LanguageSelector navClose={navClose} />
          </div>
        </nav>
      ) : null}

      <Hamburger isActive={isActive} navToggle={navToggle} />
    </div>
  );
}

NavMobile.defaultProps = {
  newsLink: null,
};

NavMobile.propTypes = {
  navigationTitles: PropTypes.array.isRequired,
  newsLink: PropTypes.object,
};
