import 'typeface-open-sans';
import 'typeface-playfair-display';
import { IconContext } from 'react-icons';
import PropTypes from 'prop-types';
import React from 'react';

import IEBanner from '../src/components/IEBanner';
import Layout from '../src/layout/Layout';

import '../src/styles/global.module.css';

export function wrapPageElement({ element, props }) {
  return (
    <Layout {...props}>
      <IEBanner />
      {element}
    </Layout>
  );
}

wrapPageElement.propTypes = {
  element: PropTypes.any.isRequired,
  props: PropTypes.any.isRequired,
};

export function wrapRootElement({ element }) {
  return (
    <IconContext.Provider value={{ className: 'react-icon' }}>
      {element}
    </IconContext.Provider>
  );
}

wrapRootElement.propTypes = {
  element: PropTypes.any.isRequired,
};
