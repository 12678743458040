import React from 'react';
import PropTypes from 'prop-types';
// import { Fade } from 'react-reveal';

export default function FadeInBottomSearch({ children }) {
  return (
    <>{children}</>
    // <Fade bottom duration={1500}>
    //   <>{children}</>
    // </Fade>
  );
}

FadeInBottomSearch.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
