import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import useLocaleContext from '@hooks/useLocaleContext';
import Logo from '@components/Icons/LogoFooter';

import * as styles from './footer.module.css';

export default function Footer() {
  const {
    allFooter,
    site,
  } = useStaticQuery(graphql`
    query {
      site: datoCmsSite {
        name
      }
      allFooter {
        nodes {
          documents {
            asset {
              id: originalId
              url
            }
            title
          }
          emailLabel
          id
          locale
          mapLabel
          offices {
            address
            addressZipAndCity
            email
            id
            locale
            maplink
            phonenumber
            postAddress
            postAddressZipAndCity
            title
          }
        }
      }
    }
  `);

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const locale = useLocaleContext();

  const footer = allFooter.nodes.find((item) => item.locale === locale);

  /**
   * This should prevent content-mismatch when hydrating the client with client-time that differs from build
   */
  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, [setCurrentYear]);

  return footer ? (
    <footer className={styles.footer}>
      <div className={styles.mainContent}>
        <div className={styles.logoWrapper}>
          <div className={styles.logo}>
            <Logo className={styles.logo} />
          </div>
        </div>

        <div className={styles.offices}>
          {footer.offices.map(
            ({
              address,
              addressZipAndCity,
              email,
              id,
              maplink,
              title,
              phonenumber,
              postAddress,
              postAddressZipAndCity,
            }) => (
              <div key={id}>
                <div className={styles.officeTitle}>{title}</div>
                <div className={styles.officeColumns}>
                  <div>
                    <div className={styles.officeItem}>{address}</div>
                    <div className={styles.officeItem}>{addressZipAndCity}</div>
                    <div className={styles.officeItem}>
                      <a
                        className={styles.officeLink}
                        href={maplink}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {footer.mapLabel}
                      </a>
                    </div>
                    {postAddress && postAddressZipAndCity && (
                      <div className={styles.officeItem}>
                        <span className={styles.noWrap}>
                          Post: {postAddress}
                          <br />
                          {postAddressZipAndCity}
                        </span>
                      </div>
                    )}
                  </div>
                  <div>
                    <div className={styles.officeItem}>
                      <a
                        className={styles.officeLink}
                        href={`tel:${phonenumber}`}
                      >
                        {phonenumber}
                      </a>
                    </div>
                    <div className={styles.officeItem}>
                      <a className={styles.officeLink} href={`mailto:${email}`}>
                        {footer.emailLabel}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>

      <div className={styles.secondaryContent}>
        <div className={styles.secondaryInner}>
          <div className={styles.copyright}>
            © {site.name} {currentYear}
          </div>

          <div className={styles.documents}>
            {footer.documents.map(({ asset: { id, url }, title }) => (
              <a
                className={styles.document}
                href={url}
                key={id}
                rel="noopener noreferrer"
                target="_blank"
              >
                {title}
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  ) : null;
}
