import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import useLocaleContext from '@hooks/useLocaleContext';

import Nav from './Nav';
import Logo from './Logo';

import * as styles from './header.module.css';

const navigationQuery = graphql`
  query {
    allNavigation {
      nodes {
        id
        locale
        links {
          id
          link {
            slug
          }
          title
        }
      }
    }
  }
`

export default function Header() {
  const locale = useLocaleContext();
  const { allNavigation } = useStaticQuery(navigationQuery);

  const navLocale = locale ? locale : 'sv-SE';

  const [navigationLinks] = allNavigation.nodes
    .filter(nav => nav.locale === navLocale)
    .map(nav => nav.links);

  return (
    <header className={styles.header}>
      <div className={styles.headerContent}>
        <div className={styles.nav}>
          <Nav navigationTitles={navigationLinks} />
        </div>
        <div className={styles.logo}>
          <Logo />
        </div>
      </div>
    </header>
  );
}
