exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-lawyer-js": () => import("./../../../src/templates/lawyer.js" /* webpackChunkName: "component---src-templates-lawyer-js" */),
  "component---src-templates-lawyers-js": () => import("./../../../src/templates/lawyers.js" /* webpackChunkName: "component---src-templates-lawyers-js" */),
  "component---src-templates-specialist-areas-js": () => import("./../../../src/templates/specialistAreas.js" /* webpackChunkName: "component---src-templates-specialist-areas-js" */)
}

