import React from 'react';
import PropTypes from 'prop-types';
import { animated, useSpring } from '@react-spring/web';

export default function FadeIn({ children }) {
  const styles = {
    ...useSpring({
      config: { duration: 500 },
      from: { opacity: 0 },
      to: { opacity: 1 },
    }),
    display: 'inherit',
  };

  return <animated.div style={styles}>{children}</animated.div>;
}

FadeIn.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
