import React from 'react';
import Proptypes from 'prop-types';

import Wrapper from './Wrapper';

import * as styles from './quote.module.css';

export default function Quote({ by, quote, url }) {
  return (
    <Wrapper url={url}>
      <div className={styles.quote}>{quote}</div>
      <div className={styles.quoteFrom}>{by}</div>
    </Wrapper>
  );
}

Quote.defaultProps = {
  url: '',
};

Quote.propTypes = {
  by: Proptypes.string.isRequired,
  quote: Proptypes.string.isRequired,
  url: Proptypes.string,
};
