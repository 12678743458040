import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';

import LanguageSelector from '../LanguageSelector';

import * as styles from './navDesktop.module.css';

const settingsQuery = graphql`
  query {
    allSetting {
      nodes {
        locale
      }
    }
    allFrontpage {
      nodes {
        slug
      }
    }
  }
`

export default function NavDesktop({
  navigationTitles,
  newsLink,
}) {
  const { allFrontpage, allSetting } = useStaticQuery(settingsQuery);

  const allLanguages = allSetting.nodes.map((lang) => lang.locale);
  const allFrontpageSlugs = allFrontpage.nodes.map(frontpage => frontpage.slug)

  return (
    <>
      {allLanguages.length > 1 ? <LanguageSelector /> : null}
      <nav>
        <ul className={styles.ul}>
          {navigationTitles.map(item => (
            <li className={styles.li} key={item.title}>
              <Link
                activeClassName={styles.active}
                className={styles.link}
                partiallyActive={!allFrontpageSlugs.includes(item.link.slug)}
                to={item.link.slug}
              >
                {item.title}
              </Link>
            </li>
          ))}
          {newsLink ? (
            <li className={styles.li} key={newsLink.title}>
              <Link
                activeClassName={styles.active}
                className={styles.link}
                to={newsLink.slug}
              >
                {newsLink.title}
              </Link>
            </li>
          ) : null}
        </ul>
      </nav>
    </>
  );
}

NavDesktop.defaultProps = {
  newsLink: null,
}

NavDesktop.propTypes = {
  navigationTitles: PropTypes.array.isRequired,
  newsLink: PropTypes.object
};
