import PropTypes from 'prop-types';
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import * as styles from './standard.module.css';

export default function HeroStandard({ id, image, title, subtitle }) {
  const objectPosition = image.focalPoint
    ? `${image.focalPoint.x * 100}% ${image.focalPoint.y * 100}%`
    : undefined;

  return (
    <div className={styles.heroStandardPage}>
      <GatsbyImage
        alt={image.alt}
        className={styles.backgroundImage}
        image={image.gatsbyImageData}
        objectPosition={objectPosition}
      />
      <div className={styles.heroContentWrapper}>
        <div className={`${styles.heroContent} ${id}`}>
          {subtitle && <h4 className={styles.subtitle}>{subtitle}</h4>}
          <h1 className={styles.title}>{title}</h1>
        </div>
      </div>
    </div>
  );
}

HeroStandard.defaultProps = {
  subtitle: '',
  title: '',
};

HeroStandard.propTypes = {
  id: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};
