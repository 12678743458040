import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

import { FadeIn } from '@components/Animations';
import Quotes from '@components/Quotes';

import * as styles from './frontpage.module.css';

export default function HeroFrontpage({ image, title, subtitle, quotes }) {
  const objectPosition = image.focalPoint
    ? `${image.focalPoint.x * 100}% ${image.focalPoint.y * 100}%`
    : undefined;

  return (
    <div className={styles.heroContainer}>
      {image && (
        <GatsbyImage
          alt={image.alt}
          className={styles.heroImage}
          image={image.gatsbyImageData}
          objectFit="cover"
          objectPosition={objectPosition}
        />
      )}
      <FadeIn>
        <div className={styles.contentWrapper}>
          <div className={styles.heroContent}>
            <h1 className={styles.heading}>{title}</h1>
            <h2 className={styles.subHeading}>{subtitle}</h2>
          </div>
          {quotes.length ? <Quotes quotes={quotes} /> : null}
        </div>
      </FadeIn>
    </div>
  );
}

HeroFrontpage.defaultProps = {
  subtitle: '',
  title: '',
};

HeroFrontpage.propTypes = {
  buttonText: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  quotes: PropTypes.array.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};
