import React from 'react';
import { func, bool } from 'prop-types';

import * as styles from './hamburger.module.css';

export default function Hamburger({ navToggle, isActive }) {
  return (
    <button
      aria-label="Open the menu"
      className={`${styles.hamburger} ${styles.hamburgerCollapse} ${
        isActive ? styles.isActive : undefined
      }`}
      onClick={navToggle}
      type="button"
    >
      <span className={styles.hamburgerBox}>
        <span className={styles.hamburgerInner} />
      </span>
    </button>
  );
}

Hamburger.propTypes = {
  isActive: bool.isRequired,
  navToggle: func.isRequired,
};
