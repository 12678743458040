import React from 'react';
import PropTypes from 'prop-types';
import Quote from '@components/Quote';

import { list, listItem } from './quotes.module.css';

export default function Quotes({ quotes }) {
  return (
    <ul className={list}>
      {quotes.map((item) => (
        <li className={listItem} key={item.id}>
          <Quote quote={item.quote} by={item.by} url={item.url} />
        </li>
      ))}
    </ul>
  );
}

Quotes.propTypes = {
  quotes: PropTypes.array.isRequired,
};
