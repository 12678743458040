import React from 'react';

import LogoHeader from '@components/Icons/LogoHeader';

import * as styles from './logo.module.css';

const enRegEx = /\/en/;

export default function Logo() {
  /** TODO: Fix this, use proper syntax and functions */
  const isEn = typeof window !== 'undefined' && enRegEx.test(window.location.pathname);
  return (
    <a className={styles.a} href={isEn ? '/en' : '/'}>
      <LogoHeader className={styles.svg} />
    </a>
  );
};
