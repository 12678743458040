// extracted by mini-css-extract-plugin
export var copyright = "footer-module--copyright--d4641";
export var document = "footer-module--document--59292";
export var documents = "footer-module--documents--14c42";
export var footer = "footer-module--footer--2b30a";
export var logo = "footer-module--logo--9d138";
export var logoWrapper = "footer-module--logoWrapper--94330";
export var mainContent = "footer-module--mainContent--20ce7";
export var noWrap = "footer-module--noWrap--aa925";
export var officeColumns = "footer-module--officeColumns--6a85f";
export var officeItem = "footer-module--officeItem--9f419";
export var officeLink = "footer-module--officeLink--be7cf";
export var officeTitle = "footer-module--officeTitle--350f9";
export var offices = "footer-module--offices--45bf8";
export var secondaryContent = "footer-module--secondaryContent--84e47";
export var secondaryInner = "footer-module--secondaryInner--27faf";
export var svg = "footer-module--svg--c0339";